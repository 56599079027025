/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  data() {
    return {
      iframeSrc: ""
    };
  },
  created() {
    this.iframeSrc = this.$route.params.src;
  }
};